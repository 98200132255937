import { getCurrentDate } from "~/mixins/formatting";

export const GenericMetas = () => ({
  desc1: `Aktuelle Heizölpreise in der Nähe vergleichen ✚ Heizöl kaufen heute am ${getCurrentDate()} mit esyoil ✔️Heizölhändler in der Nähe ✔️Heizölpreise 24h aktuell ✔️Stiftung Warentest: Sehr gut!`,
  desc2:
    "Stiftung Warentest: sehr gut! ✓ N24 Testsieger ✓ Über 500.000 zufriedene Kunden ✓ Aktuelle Ölpreise ✓ Größter Vergleich - 820 Heizöl-Händler ✓",
});

export const MetaTags = () => ({
  index: {
    title: `Heizölpreise vergleichen aktuell ${getCurrentDate()} | Heizöl kaufen in der Nähe | esyoil`,
    meta: [
      {
        hid: "title",
        name: "title",
        content: `Heizölpreise vergleichen aktuell ${getCurrentDate()} | Heizöl kaufen in der Nähe | esyoil`,
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc1,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      {
        name: "keywords",
        content:
          "Heizölpreise, Heizölpreise vergleichen, Heizöl kaufen, Heizölpreise in der Nähe",
      },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "e-peilstab-plus": {
    title: "e-Peilstab plus",
    meta: [
      { hid: "title", name: "title", content: "e-Peilstab plus" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc2,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "e-peilstab-info": {
    title: "Öltankanzeige | Füllstand elektronisch messen » e-Peilstab plus",

    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Öltankanzeige | Füllstand elektronisch messen » e-Peilstab plus",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "✅ Jetzt bequem Heizölstand messen ✅ Heizölkauf besser planen ✅ elektr. Tankanzeige ✅ Nur 119,99 &euro; ✅ Jetzt online bestellen",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "e-Peilstab plus, Heizöltank Messung" },
    ],
  },
  bestellung: {
    title:
      "Aktuelle Heizölpreise vergleichen | Jetzt günstig Heizöl in ###zipcode###, ###cityname### kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Aktuelle Heizölpreise vergleichen | Jetzt günstig Heizöl in ###zipcode###, ###cityname### kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc1,
      },
      { hid: "robots", name: "robots", content: "noindex,nofollow,noodp" },
    ],
  },
  "bestellung-generic": {
    title: "Heizöl bestellen bei esyoil",
    meta: [
      { hid: "title", name: "title", content: "Heizöl bestellen bei esyoil" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Heizölvergleich bei esyoil, einfach günstig und den eigenen Heizölanbieter sicher wechseln.",
      },
      { hid: "robots", name: "robots", content: "noindex, nofollow, noodp" },
    ],
  },
  "state-pages": {
    title:
      "Heizölpreise ###state### aktuell | Ölpreis ###zipcode### ###state### heute vergleichen | Heizöl kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Heizölpreise ###state### aktuell | Ölpreis ###zipcode### ###state### heute vergleichen | Heizöl kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Heizölpreise ###state### aktuell ⭐ Ölpreis ###state### heute ⭐ Heizöl in ###zipcode### ###state### vergleichen & kaufen ✔️Tagesaktuelle Heizölpreise in der Nähe ✔️Günstig online bestellen ✔️Flexible Zahlarten",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Heizölpreise ###state###, Ölpreis ###state###, Heizöl ###state###, Heizölpreis ###zipcode### ###state###",
      },
    ],
  },
  "state-overview": {
    title: "`Bundesländer in der Übersicht",
    meta: [
      { hid: "title", name: "title", content: "Bundesländer in der Übersicht" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Bundesländer in der Übersicht. Preise auf Bundeslandebene vergleichen.",
      },
    ],
  },
  "federal-states": {
    title:
      "Heizölpreise ###category### aktuell: Ölpreis ###category### vergleichen & heute kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Heizölpreise ###category### aktuell: Ölpreis ###category### vergleichen & heute kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Heizölpreise ###category### aktuell ⭐ Ölpreis ###category### heute am ${getCurrentDate()} ⭐ Heizöl in ###category###  vergleichen & kaufen ✔️Tagesaktuelle Heizölpreise ✔️Günstig online bestellen ✔️Flexible Zahlarten`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Heizölpreise ###category###, Ölpreis ###category###, Heizöl ###category###",
      },
    ],
  },
  landkreis: {
    title:
      "Heizölpreise Landkreis ###name### aktuell: Ölpreis Landkreis ###name### vergleichen & heute kaufen",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Heizölpreise Landkreis ###name### aktuell: Ölpreis Landkreis ###name### vergleichen & heute kaufen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Heizölpreise Landkreis ###name### aktuell ⭐ Ölpreis Landkreis ###name### heute am ${getCurrentDate()} ⭐ Heizöl im Landkreis ###name### vergleichen & kaufen ✔️Tagesaktuelle Heizölpreise ✔️Günstig online bestellen ✔️Flexible Zahlarten`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Heizölpreise Landkreis ###name###, Ölpreis Landkreis ###name###, Heizöl Landkreis ###name###",
      },
    ],
  },
  city: {
    title: "Heizöl in ###name###: Jetzt vergleichen & sparen mit esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizöl in ###name###: Jetzt vergleichen & sparen mit esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Jetzt kaufen & Geld sparen! Heizöl-Angebote im Vergleich ➽ Raum ###name### & Umgebung ⏰ Tagesaktuelle Heizölpreise ✓ Günstig ✓ Flexible Zahlarten`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  oelpreis: {
    title: "Ölpreis aktuell | Rohölpreis & Ölpreisentwicklung | Ölpreis heute",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Ölpreis aktuell | Rohölpreis & Ölpreisentwicklung | Ölpreis heute",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Ölpreis aktuell ✚ Rohölpreis aktuell Ölpreise heute am ${getCurrentDate()} Rohöl- & Ölpreisentwicklung ✚ Ölpreis Charts Brent Rohöl Preis & Chart Heizöl Preis & Chart Gasöl Preis & Chart`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      {
        name: "keywords",
        content:
          "Ölpreis, Ölpreis aktuell, Rohölpreis, Rohölpreis aktuell, Ölpreisentwicklung",
      },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  fachbetriebe: {
    title: "Fachbetriebe - Tankreinigung",
    meta: [
      { hid: "title", name: "title", content: "Fachbetriebe - Tankreinigung" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc2,
      },
      {
        hid: "keywords",
        name: "keywords",
        content:
          "heizölpreise, heizöl, heizoelpreis, ölpreis, heizoelpreise, heizoelpreis, heizoel, aktuelle heizölpreise, günstige Heizölpreise, heizöl preisvergleich, ölpreise, rohöl",
      },
      {
        hid: "page-topic",
        name: "page-topic",
        content:
          "heizölpreise, heizölpreis, heizöl, heizoelpreise, heizoelpreis, heizoel, aktuelle heizölpreise, günstige Heizölpreise, heizöl preisvergleich",
      },
    ],
  },
  peiltabellen: {
    title: "Peiltabellen - Tankreinigung",
    meta: [
      { hid: "title", name: "title", content: "Peiltabellen - Tankreinigung" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc2,
      },
      {
        hid: "keywords",
        name: "keywords",
        content:
          "heizölpreise, heizöl, heizoelpreis, ölpreis, heizoelpreise, heizoelpreis, heizoel, aktuelle heizölpreise, günstige Heizölpreise, heizöl preisvergleich, ölpreise, rohöl",
      },
      {
        hid: "page-topic",
        name: "page-topic",
        content:
          "heizölpreise, heizölpreis, heizöl, heizoelpreise, heizoelpreis, heizoel, aktuelle heizölpreise, günstige Heizölpreise, heizöl preisvergleich",
      },
    ],
  },
  "t-peilstab": {
    title: "Der t-Peilstab von esyoil - jetzt anfragen",
    meta: [
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "title",
        name: "title",
        content: "der t-Peilstab von esyoil - jetzt anfragen",
      },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc2,
      },
    ],
  },
  tankreinigung: {
    title: "Tankreinigung",
    meta: [
      { hid: "title", name: "title", content: "Tankreinigung" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc2,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "customer-ratings": {
    title: "Kundenmeinungen",
    meta: [
      { hid: "title", name: "title", content: "Kundenmeinungen" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content:
          "Echte Kunden-Erfahrungen bei esyoil. Prüfen Sie Ihren Heizöl-Händler! Meinungen, Bewertungen, Kommentare und Erfahrungsberichte über den Heizöl-Handel.",
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "Bewertungen, Heizölhändler bewerten",
      },
      {
        hid: "page-topic",
        name: "page-topic",
        content:
          "heiz&ouml;lpreise, heiz&ouml;lpreis, heiz&ouml;l, heizoelpreise, heizoelpreis, heizoel, aktuelle heiz&ouml;lpreise, g&uuml;nstige Heiz&ouml;lpreise, heiz&ouml;l preisvergleich",
      },
    ],
  },
  "co2-steuer": {
    title: "CO2-Abgabe ab 2021 - Jetzt informieren",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "CO2-Abgabe ab 2021 - Jetzt informieren",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Die CO2-Abgabe ab 2021 ► Was bedeutet das für den Heizölpreis? Alle wichtigen Informationen zum Klimaschutzprogramm von esyoil",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "CO2-Steuer, CO2-Abgabe" },
    ],
  },
  faq: {
    title: "Wir beantworten häufig gestelle Fragen - esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Wir beantworten häufig gestelle Fragen - esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc2,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "Hilfebereich, faq" },
    ],
  },
  "heizoel-sorten": {
    title: "Alle Heizöl-Sorten im Überblick - esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Alle Heizöl-Sorten im Überblick - esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: GenericMetas().desc2,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Heizöl-Sorten, Normal Schwefelarm, Premium Schwefelarm, Bio 10, Bio 15",
      },
    ],
  },
  "quality-control": {
    title:
      "TÜV zertifiziertes Qualitätsmanagement bei esyoil – ein Dienst der agital.online GmbH",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "TÜV zertifiziertes Qualitätsmanagement bei esyoil – ein Dienst der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Qualitätsmanagement nach ISO 9001 bei der agital.online GmbH ✅ Zertifiziert durch den TÜV Nord für alle Dienste des Heizöl Preisvergleichs",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "Qualitätsmanagement" },
    ],
  },
  ratenkauf: {
    title: "Heizöl auf Raten kaufen - Jetzt Händlerangebote vergleichen!",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizöl auf Raten kaufen - Jetzt Händlerangebote vergleichen!",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Zahlen Sie Ihr Heizöl sicher und bequem in 3, 6, 9 oder 12 monatlichen Raten ✅ Bei esyoil Heizölpreise vergleichen und mit dem Ratenkauf finanziell flexibel bleiben",
      },
      { name: "keywords", content: "Heizöl Ratenkauf" },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  news: {
    title: "###title###",
    meta: [
      { hid: "title", name: "title", content: "###title###" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "description", name: "description", content: "###description###" },
      { hid: "image", name: "image", content: "###image###" },
      { hid: "url", name: "url", content: "###url###" },
      { hid: "page-topic", name: "page-topic", content: "###title###" },
      { hid: "keywords", name: "keywords", content: "###title###" },
      { hid: "robots", name: "robots", content: "###robots###" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "news-amp": {
    title: "###title###",
    meta: [
      { hid: "title", name: "title", content: "###title###" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "description", name: "description", content: "###description###" },
      { hid: "page-topic", name: "page-topic", content: "###title###" },
      { hid: "keywords", name: "keywords", content: "###title###" },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
    ],
  },
  "news-charts": {
    title: "Aktuelle Charts der Heizölpreise und Ölpreise bei esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Aktuelle Charts der Heizölpreise und Ölpreise bei esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "page-topic",
        name: "page-topic",
        content:
          "heiz&ouml;lpreise, heiz&ouml;lpreis, heiz&ouml;l, heizoelpreise, heizoelpreis, heizoel, aktuelle heiz&ouml;lpreise, g&uuml;nstige Heiz&ouml;lpreise, heiz&ouml;l preisvergleich",
      },
      {
        hid: "description",
        name: "description",
        content:
          "Alles auf einen Blick ✓ Die aktuellen Heizölpreise ✓ Die aktuellen Ölpreise ✓ Informieren Sie sich über die Entwicklung in unseren Charts.",
      },
    ],
  },
  "news-overview": {
    title: "###title###",
    meta: [
      { hid: "description", name: "description", content: "###description###" },
      { hid: "title", name: "title", content: "###title###" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "Ölpreis wird weiter fallen, aktuelle Nachrichten Ölpreis",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "news-category": {
    title: "###title###",
    meta: [
      { hid: "title", name: "title", content: "###title###" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "description", name: "description", content: "###value###" },
      {
        hid: "keywords",
        name: "keywords",
        content: "###keywords###",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  "news-archive": {
    title: "Heizöl News Archiv",
    meta: [
      { hid: "title", name: "title", content: "Heizöl News Archiv" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "###keywords###",
      },
      {
        hid: "description",
        name: "description",
        content: "Liste der 15 neuesten Kommentare",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  partner: {
    title: "###title###",
    meta: [
      { hid: "title", name: "title", content: "###title###" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "description", name: "description", content: "###content###" },
      {
        hid: "robots",
        name: "robots",
        content: "###robots###",
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "###keywords###",
      },
    ],
  },
  "partner-list": {
    title: `Heizöl-Händlerliste: ###letter###`,
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizöl-Händlerliste: ###letter###",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content: `Heizöl-Händlerliste der mit ###letter### anfangenden Heizöl-Händler und deren Bewertungen auf esyoil`,
      },
      {
        hid: "keywords",
        name: "keywords",
        content: "Heizöl-Händler, Heizöl-Händlerliste, Heizölpreise, Heizöl",
      },
    ],
  },
  app: {
    title: "Heizölpreise App downloaden & los sparen » esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizölpreise App downloaden & los sparen » esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Jetzt die Heizölpreise App von esyoil downloaden & immer tagesaktuell informiert sein! Heizölpreise vergleichen ✅ Heizöl bestellen ✅ Regionale Händler finden ✅ Mobil für Android oder iOS Geräte ✅ Kostenloser Download im Apple App oder Google Play Store ▶ Jetzt runterladen und los sparen!",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "Heizölpreise App, Preisvergleich-App" },
    ],
  },
  "heizoel-lieferung": {
    title: "Tipps rund um die Heizöllieferung: Das sollten Sie beachten!",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Tipps rund um die Heizöllieferung: Das sollten Sie beachten!",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Die nächste Heizöllieferung steht bei Ihnen an? Wir zeigen Ihnen, worauf Sie für einen reibungslosen Ablauf achten sollten.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content: "Heizöllieferung, Heizöllieferung Checkliste",
      },
    ],
  },
  "heizoel-verbrauch": {
    title: "Heizölverbrauch - einfach berechnen & effizient senken",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizölverbrauch - einfach berechnen & effizient senken",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Jetzt Heizölverbrauch berechnen✓ Spartipps umsetzen✓ Energieverbrauch senken✓ - Empfehlungen von esyoil",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Herzölverbrauch, Heizöl-Jahresverbrauch, Heizölverbrauchs-Rechner",
      },
    ],
  },
  "historien-rechner": {
    title: "Heizölpreis-Archiv der letzten 10 Jahre von esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Heizölpreis-Archiv der letzten 10 Jahre von esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        hid: "description",
        name: "description",
        content:
          "Mit dem Historienpreisrechner können Sie das esyoil Heizölpreis-Archiv der letzten 10 Jahre nach Vergangenheitsdaten durchsuchen.",
      },
      {
        hid: "page-topic",
        name: "page-topic",
        content:
          "heiz&ouml;lpreise, heiz&ouml;lpreis, heiz&ouml;l, heizoelpreise, heizoelpreis, heizoel, aktuelle heiz&ouml;lpreise, g&uuml;nstige Heiz&ouml;lpreise, heiz&ouml;l preisvergleich",
      },
      { name: "keywords", content: "Historische Heizölpreise" },
    ],
  },
  oelheizung: {
    title: "Ölheizung - ein Klassiker || effektiv ✓ sparsam ✓ zuverlässig ✓",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Ölheizung - ein Klassiker || effektiv ✓ sparsam ✓ zuverlässig ✓",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Planen Sie den Neukauf oder Austausch Ihrer Heizungsanlage? Moderne Ölheizungen sind effektiv, sparsam und zuverlässig",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content:
          "Ölheizung, Ölheizung Wartung, Gasheizung, Wärmepumpe, Fernwärme, Hybrid-Heizungen",
      },
    ],
  },
  "tips-and-tricks": {
    title: "Tipps zum Heizöl-Kauf vorgestellt von Esyoil",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Tipps zum Heizöl-Kauf vorgestellt von Esyoil",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Sparen Sie beim Heizölkauf. Tipps für einen günstigen Heizölkauf - vorgestellt von esyoil.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      {
        name: "keywords",
        content: "Heizölkauf, Kaufzeitpunkt, tipps & Tricks",
      },
    ],
  },
  agb: {
    title:
      "Allgemeine Geschäfts- und Nutzungsbedingungen der agital.online GmbH",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Allgemeine Geschäfts- und Nutzungsbedingungen der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Unsere Geschäfts-und Nutzungsbedingungen informieren Sie über Widerrufsrecht, Haftung, Zahlungs-und Lieferbedingungen sowie weitere Vertragsregelungen.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "Agb" },
    ],
  },
  datenschutz: {
    title: "Datenschutz der agital.online GmbH",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Datenschutz der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: "Datenschutz der agital.online GmbH",
      },
      { name: "keywords", content: "Datenschutz" },
    ],
  },
  impressum: {
    title: "Impressum agital.online GmbH",
    meta: [
      { hid: "title", name: "title", content: "Impressum agital.online GmbH" },
      {
        hid: "description",
        name: "description",
        content:
          "Wir informieren Sie über die Kontaktmöglichkeiten des Unternehmens, die Registereinträge und die vertretungsberechtigten Personen der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "robots", name: "robots", content: "index,nofollow,noodp" },
      { name: "keywords", content: "Impressum" },
    ],
  },
  presse: {
    title: "Presse und Publikationen der agital.online GmbH",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Presse und Publikationen der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Wir bieten umfassende Informationen rund um Heizöl. Kontaktieren Sie uns zu bestimmten Medien oder informieren Sie sich in unserem Pressearchiv.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
      { name: "keywords", content: "Presse, Download" },
    ],
  },
  esyoil: {
    title: "agital.online GmbH - Das Unternehmen",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "agital.online GmbH - Das Unternehmen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Unsere Mitarbeiter sind einzigartig und unser Unternehmen ist es auch! Wir sind Pioniere im Preisvergleich für Heizöl und digitalisieren den Heizölmarkt...",
      },
      { name: "keywords", content: "esyoil Historie" },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  unternehmen: {
    title: "agital.online GmbH - Das Unternehmen",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "agital.online GmbH - Das Unternehmen",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Unsere Mitarbeiter sind einzigartig und unser Unternehmen ist es auch! Wir sind Pioniere im Preisvergleich für Heizöl und digitalisieren den Heizölmarkt...",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  team: {
    title: "Das esyoil Team - Unsere Mitarbeiter",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Das esyoil Team - Unsere Mitarbeiter",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Alle esyoil Mitarbeiter auf einen Blick. Werde Teil unseres Teams und bewirb Dich jetzt direkt oder initiativ.",
      },
      { hid: "robots", name: "robots", content: "noindex,follow,noodp" },
      { name: "keywords", content: "esyoil Team" },
    ],
  },
  unternehmer: {
    title: "esyoil - Der Unternehmer Klaus Bergmann",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "esyoil - Der Unternehmer Klaus Bergmann",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content:
          "Der Unternehmer Klaus Bergmann ist das Gesicht von esyoil und gründete das Unternehmen bereits im Jahr 2002.",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  jobs: {
    title: "###position### - Karriere bei esyoil",
    meta: [
      { hid: "title", name: "title", content: "Karriere bei esyoil" },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: `Ein Job als ###position### bei der agital.online GmbH`,
      },
    ],
  },
  "jobs-all": {
    title: "Jobs bei esyoil in Lüneburg | Karriere bei der agital.online GmbH",
    meta: [
      {
        hid: "title",
        name: "title",
        content:
          "Jobs bei esyoil in Lüneburg | Karriere bei der agital.online GmbH",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      {
        hid: "description",
        name: "description",
        content: "Esyoil - Job & Karriere ✅ Jetzt informieren & bewerben!",
      },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
  betriebsanleitungen: {
    title: "Laden Sie die e-Peilstab plus Betriebsanleitung herunter",
    meta: [
      {
        hid: "title",
        name: "title",
        content: "Laden Sie die e-Peilstab plus Betriebsanleitung herunter",
      },
      {
        hid: "image",
        name: "image",
        content: `###image###`,
      },
      { hid: "description", name: "description", content: "" },
      { hid: "robots", name: "robots", content: "index,follow,noodp" },
      { name: "revisit-after", content: "1 days" },
    ],
  },
});
