// Libraries
import dayjs from "dayjs";
import "dayjs/locale/de";
import isoWeek from "dayjs/plugin/isoWeek";
import { BreadCrumb } from "~/types/BreadCrumb";
import { translateStateName } from "./mixins";
dayjs.extend(isoWeek);
dayjs.locale("de");

export const returnFormattedDate = (dateString: string, dateFormat: string) => {
  dayjs.locale("de");
  let retVal = dayjs(dateString).format(dateFormat);
  if (dateFormat === "today") retVal = dayjs().format("DD.MM.YYYY");
  if (dateFormat === "day") retVal = dayjs(dateString).format("DD");
  if (dateFormat === "weekDay") retVal = dayjs(dateString).format("dddd");
  if (dateFormat === "month") retVal = dayjs(dateString).format("MMMM");
  if (dateFormat === "year") retVal = dayjs(dateString).format("YYYY");

  return retVal;
};

export const returnPriceFormatDE = (price: number | string) => {
  if (price === null) return;
  const NumberPrice = Number(price);
  return NumberPrice.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  });
};

export const returPriceTrendFormatDE = (price: number | string) => {
  if (price === null) return;
  const NumberPrice = Number(price);
  if (Number(NumberPrice.toFixed(2)) < 0)
    return NumberPrice.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  if (Number(NumberPrice.toFixed(2)) === 0) return `0,00 €`;
  return `+${NumberPrice.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
  })}`;
};

export const removeParagraphTag = (paragraphContent: string) => {
  if (paragraphContent === null) return;
  const PlainTextString = paragraphContent.replace(
    /(<p[^>]+?>|<p>|<\/p>)/gim,
    "",
  );
  return PlainTextString;
};

export const getPriceDifference = (price1, price2) => {
  const numericCurrentPrice = parseFloat(
    __formatPriceWithoutEuroSign(price1)
      .replace(/[^0-9.,]+/g, "")
      .replace(",", ""),
  );
  const numericLastPrice = parseFloat(
    __formatPriceWithoutEuroSign(price2)
      .replace(/[^0-9.,]+/g, "")
      .replace(",", ""),
  );
  const difference = Math.abs(numericCurrentPrice - numericLastPrice);
  return {
    difference,
    numericCurrentPrice,
    numericLastPrice,
  };
};

const __formatPriceWithoutEuroSign = (price: string): string => {
  return returnPriceFormatDE(price).replace("€", "");
};

export const formatBreadCrumbByCountyAndBundesland = (
  fullPath: string,
  router: any,
): BreadCrumb[] => {
  const params = fullPath.startsWith("/")
    ? fullPath.substring(1).split("/")
    : fullPath.split("/");

  const crumbs = __createBreadCrumbsForCountyAndBundesland(params, router);

  return crumbs;
};

const __createBreadCrumbsForCountyAndBundesland = (
  params: any,
  router: any,
): BreadCrumb[] => {
  const crumbs: BreadCrumb[] = [];

  params.reduce((path, param) => {
    const currentPath = `${path}/${param}`;
    const match = router.match(currentPath);
    const routeParams = match.params;

    if (Object.keys(routeParams).length > 0) {
      const routeParamKeys = Object.keys(routeParams);
      let stateCountyPath = "/heizoelpreise/bundeslaender";

      routeParamKeys.forEach((paramName) => {
        const paramValue = routeParams[paramName];
        const isStateOrCounty = paramName === "category" || paramName === "id";
        // Append the parameter value to the state/county path if it's a category or id
        if (isStateOrCounty) {
          stateCountyPath += `/${paramValue}`;
        }

        if (
          !crumbs.some((crumb) => crumb.name === translateStateName(paramValue))
        ) {
          crumbs.push(__createCrumb(paramValue, stateCountyPath));
        }
      });
    }

    return currentPath;
  }, "");

  return crumbs;
};

const __createCrumb = (
  paramValue: string,
  stateCountyBaseUrl: string,
): BreadCrumb => {
  return {
    name: translateStateName(paramValue),
    linkUrl: `${stateCountyBaseUrl}`,
    linkValue: `${stateCountyBaseUrl}`,
  };
};

export const getFormattedPercentage = (
  currentPrice: number,
  lastPrice: number,
) => {
  if (currentPrice == null || lastPrice == null) return "-";
  const relativeChangePercentage = ((currentPrice - lastPrice) / lastPrice) * 100;
  return __returnPriceTrendPercentDE(relativeChangePercentage);
};

const __returnPriceTrendPercentDE = (percentage: number | string) => {
  if (percentage === null) return;
  const NumberPrice = Number(percentage);
  if (NumberPrice <= 0)
    return `${NumberPrice.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}%`;
  return `+${NumberPrice.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}%`;
};

export const getCurrentDate = () => {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear().toString().slice(-2);

  const formattedDate = `${day < 10 ? "0" + day : day}.${
    month < 10 ? "0" + month : month
  }.${year}`;
  return formattedDate;
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const replaceToUmlauts = (param: string): string => {
  return param.replace("ae", "ä").replace("oe", "ö").replace("ue", "ü");
};
