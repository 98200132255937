import {
  Products,
  DeliveryTruck,
  PaymentTypes,
  DeliveryTimes,
} from "~/operations/messages";

export const translateCheckoutParameter = function translateCheckoutParameter(
  parameterName: string,
  value,
): string {
  if (parameterName === "Schlauchlänge") {
    switch (value) {
      case "fortyMetre":
        return DeliveryTruck.hoseLength1;
      case "sixtyMetre":
        return DeliveryTruck.hoseLength2;
      case "eightyMetre":
        return DeliveryTruck.hoseLength3;
    }
  }
  if (parameterName === "Zahlungsmittel") {
    switch (value) {
      case "ec":
        return PaymentTypes.ec;
      case "cash":
        return PaymentTypes.cash;
      case "guaranteedPayment":
        return PaymentTypes.guaranteedPayment;
      case "invoice":
        return PaymentTypes.invoice;
      case "directDebit":
        return PaymentTypes.directDebit;
      case "inAdvance":
        return PaymentTypes.inAdvance;
    }
  }
  if (parameterName === "Tankwagen") {
    switch (value) {
      case "withTrailer":
        return DeliveryTruck.withTrailer;
      case "withoutTrailer":
        return DeliveryTruck.withoutTrailer;
    }
  }
  if (parameterName === "Lieferzeit") {
    return DeliveryTimes.find((e) => e.value == value).name;
  }
  if (parameterName === "Produktname") {
    switch (value) {
      case "normal":
        return Products.normal;
      case "premium":
        return Products.premium;
      case "climateNeutralPremium":
        return Products.climateNeutralPremium;
      case "climateNeutralNormal":
        return Products.climateNeutralNormal;
      case "bio10":
        return Products.bio10;
      case "bio15":
        return Products.bio15;
      case "bio10Premium":
        return Products.bio10Premium;
    }
  }
  return value;
};

export const translateStateToNutsCode = function translateStateToNutsCode(
  cityName,
): string {
  const cityNameIndex = cityName.toLowerCase();
  const states = {
    "baden-württemberg": "DE1",
    bayern: "DE2",
    berlin: "DE3",
    brandenburg: "DE4",
    bremen: "DE5",
    hamburg: "DE6",
    hessen: "DE7",
    "mecklenburg-vorpommern": "DE8",
    niedersachsen: "DE9",
    "nordrhein-westfalen": "DEA",
    "rheinland-pfalz": "DEB",
    saarland: "DEC",
    sachsen: "DED",
    "sachsen-anhalt": "DEE",
    "schleswig-holstein": "DEF",
    thüringen: "DEG",
  };
  return states[cityNameIndex];
};

export const translateStateName = function translateStateName(
  cityName,
): string {
  if (!cityName) {
    return;
  }
  let stateNameSegments = cityName;
  if (cityName.includes("-")) {
    stateNameSegments = cityName.split("-");
  } else if (cityName.includes(" ")) {
    stateNameSegments = cityName.split(" ");
  }
  function returnFirstLetterUppercase(segment: string) {
    if (segment.includes("(")) {
      return (
        "(" + segment.charAt(1).toUpperCase() + segment.slice(2).toLowerCase()
      );
    }
    return segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase();
  }

  if (stateNameSegments.length === 2 && cityName.includes("-"))
    return `${returnFirstLetterUppercase(
      stateNameSegments[0],
    )}-${returnFirstLetterUppercase(stateNameSegments[1])}`;
  else if (stateNameSegments.length === 2 && cityName.includes(" "))
    return `${returnFirstLetterUppercase(
      stateNameSegments[0],
    )} ${returnFirstLetterUppercase(stateNameSegments[1])}`;
  else return returnFirstLetterUppercase(cityName);
};

export default {
  translateCheckoutParameter,
  translateStateToNutsCode,
  translateStateName,
};
