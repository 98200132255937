import { MetaTags } from "./meta-helper";

export const generateMetaTags = (page, replacements = []) => {
  const tags = JSON.parse(JSON.stringify(MetaTags()[page]));
  tags.meta = generateOpenGraphFromMeta(tags, replacements);
  tags.title = replacePlaceHolderIfNecessary(tags.title, replacements);

  return tags;
};

export const generateOpenGraphFromMeta = (tags, replacements) => {
  // filter out invalid og tags
  tags.meta.forEach(
    (t) => (t.content = replacePlaceHolderIfNecessary(t.content, replacements)),
  );

  let validTags = tags.meta.filter((e) =>
    ["title", "description", "image", "url"].includes(e.name),
  );

  validTags = validTags.map((tag) => {
    return {
      hid: `og:${tag.hid}`,
      property: `og:${tag.name}`,
      content: `${tag.content}`,
    };
  });
  return [...validTags, ...tags.meta];
};

/**
 *
 * this function replaces ###key### value stuff in the meta strings.
 *
 * @param data key-value array of replacements ({ k: 'zipcode', v: '21339' })
 */
export const replacePlaceHolderIfNecessary = (input: string, data: any) => {
  data.forEach((r: any) => {
    const regex = new RegExp(`###${r.k}###`, "g");
    input = input.replace(regex, r.v);
  });

  return input;
};

export const tabExitHandler = (instance) => {
  let timer = null;
  window.onpagehide = async (e) => {
    if ({ focus: 1, pageshow: 1 }[e.type]) {
      // window.clearInterval(timer);
      window.clearTimeout(timer);
      document.title = instance.$root.$meta().refresh().metaInfo.titleChunk;
    } else {
      timer = setTimeout(() => {
        // TODO: possible memory leak
        document.title = "⭐ Bis gleich! 👍";
      }, 2500);
    }
  };
};
