export const InputFieldLabel: { [key: string]: any } = {
  company: "Firma",
  salutation: "Anrede",
  firstName: "Vorname",
  lastName: "Nachname",
  location: "Ort",
  district: "Ortsteil",
  zipcode: "Postleitzahl",
  street: "Straße",
  houseNumber: "Hausnummer",
  phone: "Telefonnummer",
  mail: "E-Mail-Adresse",
  additionalPhone: "Zusätzliche Telefonnummer",
  birthday: "Geburtsdatum",
  amount: "Menge (in Litern)",
  unloadingPoints: "Entladestellen",
  deliverryTime: "Lieferoption",
  paymentType: "Zahlungsart",
  type: "Sorte",
  hoseLength: "Schlauchlänge",
  trailer: "Tankwagen",
};

export const InputPlaceholder: { [key: string]: any } = {
  company: "Name Ihrer Firma",
  salutation: "Anrede",
  firstName: "Ihr Vorname",
  firstNameInvoice: "Vorname des Empfängers",
  lastName: "Ihr Nachname",
  lastNameInvoice: "Nachname des Empfängers",
  street: "Straßenname",
  deliveryLocation: "Name des Lieferortes",
  deliveryLocationBilling: "Ort Rechnungsadresse",
  district: "Name des Ortsteils",
  zipcode: "Ihre Postleitzahl",
  location: "Ihr Wohnort",
  houseNumber: "Ihre Hausnummer",
  houseNumberInvoice: "Hausnummer des Empfängers",
  phone: "04131 680 498 95",
  additionalPhone: "0162 123 456 78",
  mail: "esyoil@esyoil.com",
  birthday: "Geburtsdatum",
  amount: "3000",
  amountReducet: "min. 500",
};

export const LabelAdditives: { [key: string]: any } = {
  required: "*",
  fixed: "(unveränderlich)",
  optional: "(optional)",
  invoiceRecipient: "(des Rechnungsempfängers)",
  ifCommercial: "(falls gewerblich)",
};

export const InputFieldErrors: { [key: string]: any } = {
  notCompare: "Die Angaben stimmen nicht überein",
  required: "Pflichtfeld",
  wrongEmailAddress: "Keine gültige E-Mail",
  wrongPasswordPattern: "Passwort entspricht nicht den Vorgaben",
  percent: "min. 0 und max. 100 %",
};

export const ButtonText: { [key: string]: any } = {
  compare: "Vergleichen",
  comparePrices: "Preise vergleichen",
  unfold: "Inhalt anzeigen",
  fold: "Inhalt ausblenden",
  showDetails: "Details anzeigen",
  hideDetails: "Details ausblenden",
  expandOptions: "Mehr Optionen anzeigen",
  reduceOptions: "Weniger Optionen anzeigen",
  show: "Anzeigen",
  hide: "Ausblenden",
  priceUp: "Preis steigt",
  priceDown: "Preis sinkt",
  enterCheckout: "Zur Bestellung",
  rateDealer: "Händler bewerten",
  rateEsyoil: "esyoil bewerten",
  calculate: "Rechnen",
  startProcess: "Starten",
  goBack: "Zurück",
  next: "Weiter",
  signup: "Anmelden",
  loadMore: "Weitere laden",
  savePDF: "Als PDF speichern",
  saveMountingInstructionsPDF: "Montageanleitung herunterladen",
  buyNowCTA: "Jetzt bestellen",
  notAvailable: "Derzeit nicht verfügbar",
  literallyBuyNow: "Jetzt kostenpflichtig bestellen",
  print: "Ausdrucken",
  closeModal: "Fenster Schließen",
  userAccount: "Mein Konto",
  searchDealer: "Suchen",
  findDealer: "Fachbetriebe finden",
  cancel: "Abbrechen",
  close: "Schließen",
};

export const PriceValues: { [key: string]: any } = {
  valueAddedTax: "19%",
  ePeilstabPrice: 119.99,
  placementFee: 15,
  placementFeeString: "15,00",
};

export const Cetificates: { [key: string]: any } = {
  ral: "RAL-Gütezeichen",
};

export const EsyOilContact: { [key: string]: any } = {
  customerServicePhone: "04131 680 498 95",
  customerServiceTimes: "Mo–Fr, 9–17 Uhr",
  partnerServicePhone: "04131 680 498 93",
  partnerServiceTimes: "Mo-Fr, 9–17 Uhr",
  pressPhone: "04131 680 498 00",
  agbPhone: "04131 68 04 98 00",
  pressMail: "presse@esyoil.com",
  saleMail: "vertrieb@esyoil.com",
  peilstabMail: "peilstab@esyoil.com",
  esyMail: "esyoil@esyoil.com",
  recallMail: "widerruf@esyoil.com",
  applyMail: "bewerbung@esyoil.de",
  companyName: "agital.online GmbH",
  companyAddress: "Hamburger Str. 35",
  companyZipcode: "21339",
  companyLocation: "Lüneburg",
};

// !! IMPORTANT !! Reihenfolge ist für das select in der Preisausgabe wichtig
export const Products: { [key: string]: any } = {
  normal: "Normal Schwefelarm",
  premium: "Premium Schwefelarm",
  bio10: "Bio 10",
  bio10Premium: "Bio 10 Premium",
  bio15: "Bio 15",
  betriebsanleitungen: "Betriebsanleitungen",
  climateNeutralNormal: "CO2-Kompensiert Normal",
  climateNeutralPremium: "CO2-Kompensiert Premium",
  mainApp: "Heizölpreise-App",
  ePeilstab: "e-Peilstab plus",
  tPeilstab: "t-Peilstab",
};

export const Regex: { [key: string]: any } = {
  phone: /^\d{6,}$/, // Matches only numbers, minimum length 6
  // Matches (English letters, numbers & special characters)@(English letters, numbers & special characters).(English letters, numbers)
  mail: /^[a-zA-Z0-9!#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]+@[a-zA-Z0-9!#$%^&*()_+\-=[\]{};':"\\|,.<>\/?]+(?:\.[a-zA-Z0-9]+)+$/,
  name: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð,.\'-\\s]+/,
  iban: /[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?/,
};

export const Descriptions: { [key: string]: any } = {
  nationalPriceDisclaimer:
    "Deutschlandweite Durchschnittspreise pro 100 Liter inkl. MwSt. bei 3.000 Liter Abnahme für Normal Schwefelarmes Heizöl.",
  statesPriceDisclaimer:
    "Bundeslandweite Durchschnittspreise pro 100 Liter inkl. MwSt. bei 3.000 Liter Abnahme für Normal Schwefelarm.",
  statesPriceDisclaimerWithState:
    "Durchschnittspreise im Bundesland ##state## pro 100 Liter inkl. MwSt. bei 3.000 Liter Abnahme für Normal Schwefelarm.",
  districtPriceDisclaimer:
    "Durchschnittspreise im Landkreis ##district## pro 100 Liter inkl. MwSt. bei 3.000 Liter Abnahme für Normal Schwefelarm.",
  cmeGroupDisclaimer:
    "Die statistischen Daten, zur Abbildung der Preis-Charts stammen von der CME-Group",
};

// !! IMPORTANT !! Reihenfolge ist für das select in der Preisausgabe wichtig
export const PaymentTypes: { [key: string]: any } = {
  all: "Alle Zahlarten",
  ec: "EC-Karte",
  cash: "Barzahlung",
  invoice: "Rechnung",
  guaranteedPayment: "Ratenkauf",
  directDebit: "Lastschrift",
  inAdvance: "Vorkasse",
  heatAccount: "Wärmekonto",
};

export const DeliveryTruck: { [key: string]: any } = {
  hoseLength1: "40 Meter",
  hoseLength2: "60 Meter",
  hoseLength3: "80 Meter",
  withTrailer: "Mit Anhänger möglich",
  withoutTrailer: "Ohne Anhänger",
};

// !! IMPORTANT !! Reihenfolge ist für das select in der Preisausgabe wichtig
export const DeliveryTimes: {
  value: string;
  name: string;
  longText: string;
  hideInDialog?: boolean;
  icon?: string;
  iconColor?: string;
}[] = [
  {
    value: "normal",
    name: "Ohne",
    longText:
      "Kein Aufpreis, der Händler entscheidet über den Liefertermin innerhalb der maximalen Lieferfrist.",
    icon: "piggy-bank-solid.svg",
    iconColor: "hsla( 346, 100%, 71%, 1 )",
  },
  {
    value: "fiveTenDays",
    name: "6 - 10 Tage (Mo. - Fr.)",
    longText: "Innerhalb von 6 – 10  Tagen (Montag bis Freitag)",
    icon: "truck-solid.svg",
    iconColor: "hsla( 202, 8%, 50%, 1 )",
  },
  {
    value: "threeFiveDays",
    name: "3 - 5 Tage (Mo. – Fr.)",
    longText: "Innerhalb von 3 – 5  Tagen (Montag bis Freitag)",
    icon: "shipping-fast-solid.svg",
    iconColor: "hsla( 133, 61%, 40%, 1 )",
  },
  {
    value: "twoWorkingDays",
    name: "Express (2 Tage, Mo. - Fr.)",
    longText: "Innerhalb von 2 Tagen (Montag bis Freitag)",
    icon: "shipping-fast-solid.svg",
    iconColor: "hsla( 133, 61%, 40%, 1 )",
  },
  {
    value: "twentyFourHours",
    name: "24 Stunden",
    longText:
      "Nur bei Bestellung von Montag bis Donnerstag zwischen 6 – 16 Uhr",
    icon: "bolt-solid.svg",
    iconColor: "hsla( 44, 98%, 60%, 1 )",
  },
  {
    value: "today",
    name: "Heute",
    longText: "Nur bei Bestellung vor 10 Uhr",
    icon: "bolt-solid.svg",
    iconColor: "hsla( 44, 98%, 60%, 1 )",
  },
  {
    value: "weekTimeOne",
    name: "07:00 – 12:00 Uhr",
    longText: "Lieferung zwischen 07:00 und 12:00 Uhr",
    hideInDialog: true,
    icon: "piggy-bank-solid.svg",
    iconColor: "hsla( 202, 8%, 50%, 1 )",
  },
  {
    value: "weekTimeTwo",
    name: "12:00 – 17:00 Uhr",
    longText: "Lieferung zwischen 12:00 und 17:00 Uhr",
    hideInDialog: true,
    icon: "piggy-bank-solid.svg",
    iconColor: "hsla( 202, 8%, 50%, 1 )",
  },
];

export const LegacyProduct: { [key: number]: string } = {
  4: "premium",
  5: "bio10",
  6: "bio15",
  7: "bio10Premium",
  8: "normal",
  9: "climateNeutralNormal",
  10: "climateNeutralPremium",
};

export const LegacyPayment: { [key: number]: string } = {
  1: "cash",
  2: "ec",
  3: "inAdvance",
  4: "directDebit",
  5: "heatAccount",
  6: "invoice",
  7: "trustHandService",
  8: "cashORec",
  10: "guaranteedPayment",
};

export const HoseLength: { [key: string]: any } = {
  hose_s: "fortyMetre",
  hose_l: "sixtyMetre",
  hose_xl: "eightyMetre",
};

export const DeliveryTruckLegacy: { [key: string]: any } = {
  normal: "withTrailer",
  short_vehicle: "withoutTrailer",
};

export const DeliveryTimeInfoText =
  "Die Berechnung der Lieferzeiten basiert grundsätzlich auf den Tagen von Montag bis Freitag. Bitte beachten Sie, dass die Lieferfrist durch bundesweite und regionale Feiertage abweichen kann.";

export const APIErrorResponseMessages: { [key: string]: any } = {
  PASSWORD_WRONG: [
    "Falsches Passwort",
    "E-Mail-Adresse und Passwort passen nicht zueinander. Sollten Sie Ihr Passwort vergessen haben, nutzen Sie einfach die „Passwort vergessen“-Funktion.",
    "E-Mail-Adresse und Passwort passen nicht zueinander. Sollten Sie Ihr Passwort vergessen haben, nutzen Sie einfach die „Passwort vergessen“-Funktion.",
    "PASSWORD_WRONG",
  ],
  EMAIL_NOT_FOUND: [
    "E-Mail-Adresse unbekannt",
    "Wir konnten kein Konto mit dieser E-Mail-Adresse finden. Bitte kontrollieren Sie Ihre Eingabe.",
    "PASSWORD_WRONG",
  ],
  EMAIL_TAKEN: [
    "Diese E-Mail-Adresse ist bereits vergeben",
    'Mit dieser E-Mail-Adresse ist bei uns schon ein Konto registriert. Bitte melden Sie sich an, um "Mein Konto" aufzurufen.',
    "EMAIL_TAKEN",
  ],
  ACCOUNT_NOT_CONFIRMED: [
    "Ihr Benutzerkonto ist noch nicht aktiviert",
    "Sie haben Ihre E-Mail-Adresse noch nicht bestätigt. Bitte überprüfen Sie Ihren E-Mail-Posteingang und klicken Sie auf den Link in der E-Mail, um Ihr Konto zu bestätigen.",
    "ACCOUNT_NOT_CONFIRMED",
  ],
  CONFIRMATION_RESEND_RATELIMIT: [
    "E-Mail bereits versendet",
    "Sie haben bereits einen neuen Bestätigungslink angefordert. Bitte überprüfen Sie Ihren E-Mail-Posteingang und klicken Sie auf den Link in der E-Mail, um Ihr Konto zu bestätigen oder versuchen Sie es später erneut.",
    "CONFIRMATION_RESEND_RATELIMIT",
  ],
  INVALID_TOKEN:
    "Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an!",
  MISSING_HEADER:
    "Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an!",
  CONFIRMATION_TOKEN_INVALID: "Der Token ist ungültig.",
  CONFIRMATION_TOKEN_EXPIRED:
    "Der Token ist abgelaufen, bitte wiederholen Sie den Registrierungsprozess.",
  KID_NOT_FOUND: "Ein Benutzer mit dieser ID konnte nicht gefunden werden",
  PRIVACY_POLICY_NOT_ACCEPTED: [
    "Bitte stimmen Sie der Datennutzung zu",
    "Um unsere Dienste nutzen zu können müssen Sie unserer Datenschutzerklärung zustimmen.",
    "PRIVACY_POLICY_NOT_ACCEPTED",
  ],
  EMAIL_CHANGE_AWAITING_CONFIRMATION: [
    "E-Mailadresse ändern",
    "Sie erhalten eine E-Mail mit einem Bestätigungslink. Bitte schauen Sie in Ihr Postfach und klicken Sie auf den Link, um die neue E-Mail-Adresse zu bestätigen.",
    "EMAIL_CHANGE_AWAITING_CONFIRMATION",
  ],
  PASSWORD_EMPTY_NEW: "Bitte geben Sie Ihr neues Passwort ein.",
  PASSWORD_NEW_IS_OLD:
    "Ihr neues Passswort ist identisch mit Ihrem aktuellen Passwort. Bitte geben Sie ein anderes Passwort ein, um ein neues Passwort festzulegen.",
  PASSWORD_TOO_SHORT:
    "Ihr Passwort ist zu kurz. Verwenden Sie bitte mindestens sechs Zeichen.",
  PASSWORD_WRONG_OLD: "Ihr altes Passwort ist nicht korrekt.",
  USER_DELETED: "Ihr Konto wurde gelöscht.",
  TANK_NON_EXISTENT: "",

  MISSING_FIELDS: "Fehlende Angaben",
  MISSING_FIELD_EMAIL: "Fehlende Angaben",
  MISSING_FIELD_PASSWORD: "Fehlende Angaben",
  MISSING_FIELD_ANREDE: "Fehlende Angaben",
  MISSING_FIELD_NAME: "Fehlende Angaben",
  MISSING_FIELD_VORNAME: "Fehlende Angaben",
  MISSING_FIELD_STRASSE: "Fehlende Angaben",
  MISSING_FIELD_ZIPCODE: "Fehlende Angaben",
  MISSING_FIELD_ORT: "Fehlende Angaben",
  MISSING_FIELD_DEVICE: "Fehlende Angaben",

  internal:
    "Der Server ist momentan nicht erreichbar. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
  resourceNotFound: "Die Ressource wurde nicht gefunden.",
  accessDenied:
    "Sie haben keine ausreichende Berechtigung, um diese Aktion auszuführen.",
  unauthorized: "Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an.",
};
